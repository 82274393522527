import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Application from "../components/melb-places/application";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Application mdxType="Application" />
    <Metatags title="Melbourne places | May 29 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-29.png" url="https://cssgrid31.brett.cool/" pathname="/may-29" mdxType="Metatags" />
    <Footer date={29} prev={true} next={true} mdxType="Footer">
      <p>{`I’ve spent a fair bit of the past week and a half in various bars and restaurants in Melbourne, and thoroughly enjoyed my time.`}</p>
      <p>{`Here’s just some of my favourite `}<em parentName="p">{`new`}</em>{` places, that I can remember so far!`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      